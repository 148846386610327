a {
	color:inherit;
}
h1,h2,h3 {
	text-transform:uppercase;
	font-family: "Century Gothic", "Tw Cen MT", Futura, "URW Gothic L", Arial, sans-serif;
}
h1 {
	font-size:22px;
}
h2 {
	font-size:20px;
}
h3 { 
	font-size:14px;
}
.t-entertainmentvenues h1,
.t-entertainmentvenues h2,
.t-entertainmentvenues h3,
.t-entertainmentvenues h4,
.t-entertainmentvenues h5,
.t-entertainmentvenues h6 {	
	color:#fd8300;
}
.t-trecc h1,
.t-trecc h2,
.t-trecc h3,
.t-trecc h4,
.t-trecc h5,
.t-trecc h6 {	
	color:#72B84C;
}
.t-townhall h1,
.t-townhall h2,
.t-townhall h3,
.t-townhall h4,
.t-townhall h5,
.t-townhall h6 {	
	color:#92278F;
}
.t-capitoltheatre h1,
.t-capitoltheatre h2,
.t-capitoltheatre h3,
.t-capitoltheatre h4,
.t-capitoltheatre h5,
.t-capitoltheatre h6 {	
	color:#E72079;
}
p, input, textarea {
    margin: 0;
    padding: 0;
}
body {font-family: "Century Gothic", "Tw Cen MT", Futura, "URW Gothic L", Arial, sans-serif;overflow-x: hidden;margin: 0;line-height: 150%;}
.t-entertainmentvenues blockquote {
	background: #FD8300;
	color: #FFFFFF;
	text-align: center;
	padding: 10px !important;
	margin: 1em 0;
}
.t-trecc blockquote {
	background: #72B84C;
	color: #FFFFFF;
	text-align: center;
	padding: 10px !important;
	margin: 1em 0;
}
.t-townhall blockquote {
	background: #92278F;
	color: #FFFFFF;
	text-align: center;
	padding: 10px !important;
	margin: 1em 0;
}
.t-capitoltheatre blockquote {
	background: #E72079;
	color: #FFFFFF;
	text-align: center;
	padding: 10px !important;
	margin: 1em 0;
}
.clear-float {
    clear:both;
}
.highlight {
    color:#FD8300;
}
.t-trecc .highlight {
    color:#82B84C;
}
.t-townhall .highlight {
    color:#92278F;
}
.t-capitoltheatre .highlight {
    color:#E72079;
}
img.display-left {
    float:left;
    margin-right:10px;
    margin-bottom:10px;
}
img.display-right {
    float:right;
    margin-left:10px;
    margin-bottom:10px;
}
.t-entertainmentvenues .highlighttext {
    color: #FD8300 !important;
}
.t-trecc .highlighttext {
    color: #72B84C !important;
}
.t-townhall .highlighttext {
    color: #92278F !important;
}
.t-capitoltheatre .highlighttext {
    color: #E72079 !important;
}
.presentedby {
	font-style:italic;
	font-size:16px;
	color:#737771;
	margin-bottom:20px;
	margin-left: 15px;
}
.quote {
    text-align: center;
    background: #fd8300;
    color: #FFFFFF !important;
    font-family: "Times New Roman", Times, serif;
    font-style: italic;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: normal;
}

table.withborder td {
	border-bottom:1px solid #d9d9d9;
	padding-top:20px;
	padding-bottom:20px;
}